<template>
  <v-footer id="home-footer" dark min-height="72">
    <v-container>
      <v-row>
        <v-col>
          &copy; {{ year }} Hedge Vision Systems. All Rights Reserved. </v-col
        ><v-col>
          <div>
            <template v-for="(s, i) in social">
              <a
                :key="s"
                class="white--text pa-1 pa-md-0"
                :href="link[i]"
                target="_blank"
                v-text="s"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>
        <v-col>
          Images courtesy <a href="https://www.freepik.com">Freepik</a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  data: () => ({
    social: ["Linkedin"],
    link: [
      "https://www.linkedin.com/company/hedge-vision-systems-private-limited",
    ],
  }),
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
